<script>
  import { fade } from 'svelte/transition';
  export let dim = false;
  export let fullScreenOnMobile = false;

  $: responsiveClasses = fullScreenOnMobile
    ? 'items-stretch sm:items-center'
    : 'items-center';
</script>

<style>.dim-bg{background-color:rgba(0,0,0,.4)}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vZGVfbW9kdWxlcy9AY2FyZXZveWFuY2UvdWkvc3JjL2NvbXBvbmVudHMvVndDZW50ZXJlZC5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsUUFDRSwrQkFDRiIsImZpbGUiOiJub2RlX21vZHVsZXMvQGNhcmV2b3lhbmNlL3VpL3NyYy9jb21wb25lbnRzL1Z3Q2VudGVyZWQuc3ZlbHRlIiwic291cmNlc0NvbnRlbnQiOlsiXG4gIC5kaW0tYmcge1xuICAgIGJhY2tncm91bmQtY29sb3I6IHJnYmEoMCwgMCwgMCwgMC40KTtcbiAgfVxuIl19 */</style>

<div
  on:click
  on:mousedown
  on:mouseup
  class="fixed flex z-50 m-0 w-full h-full left-0 top-0 justify-center {responsiveClasses}"
  class:dim-bg={dim}
  transition:fade={{ duration: 200 }}>
  <slot />
</div>

<script>
  // Copied from https://github.com/RobBrazier/svelte-awesome
  let className;

  export { className as class };

  export let width;
  export let height;
  export let box;

  export let spin = false;
  export let inverse = false;
  export let pulse = false;
  export let flip = null;

  // optionals
  export let x = undefined;
  export let y = undefined;
  export let style = undefined;
  export let label = undefined;
</script>

<style>.fa-icon{display:inline-block;fill:currentColor}.fa-flip-horizontal{transform:scaleX(-1)}.fa-flip-vertical{transform:scaleY(-1)}.fa-spin{-webkit-animation:fa-spin 1s linear 0s infinite;animation:fa-spin 1s linear 0s infinite}.fa-inverse{color:#fff}.fa-pulse{-webkit-animation:fa-spin 1s steps(8) infinite;animation:fa-spin 1s steps(8) infinite}@keyframes fa-spin{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vZGVfbW9kdWxlcy9AY2FyZXZveWFuY2UvdWkvc3JjL2NvbXBvbmVudHMvc3ZnL1N2Zy5zdmVsdGUiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0UsU0FDRSxvQkFBcUIsQ0FDckIsaUJBQ0YsQ0FDQSxvQkFDRSxvQkFDRixDQUNBLGtCQUNFLG9CQUNGLENBQ0EsU0FDRSwrQ0FBd0MsQ0FBeEMsdUNBQ0YsQ0FDQSxZQUNFLFVBQ0YsQ0FDQSxVQUNFLDhDQUF1QyxDQUF2QyxzQ0FDRixDQUNBLG1CQUNFLEdBQ0Usc0JBQ0YsQ0FDQSxHQUNFLHVCQUNGLENBQ0YiLCJmaWxlIjoibm9kZV9tb2R1bGVzL0BjYXJldm95YW5jZS91aS9zcmMvY29tcG9uZW50cy9zdmcvU3ZnLnN2ZWx0ZSIsInNvdXJjZXNDb250ZW50IjpbIlxuICAuZmEtaWNvbiB7XG4gICAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICAgIGZpbGw6IGN1cnJlbnRDb2xvcjtcbiAgfVxuICAuZmEtZmxpcC1ob3Jpem9udGFsIHtcbiAgICB0cmFuc2Zvcm06IHNjYWxlKC0xLCAxKTtcbiAgfVxuICAuZmEtZmxpcC12ZXJ0aWNhbCB7XG4gICAgdHJhbnNmb3JtOiBzY2FsZSgxLCAtMSk7XG4gIH1cbiAgLmZhLXNwaW4ge1xuICAgIGFuaW1hdGlvbjogZmEtc3BpbiAxcyAwcyBpbmZpbml0ZSBsaW5lYXI7XG4gIH1cbiAgLmZhLWludmVyc2Uge1xuICAgIGNvbG9yOiAjZmZmO1xuICB9XG4gIC5mYS1wdWxzZSB7XG4gICAgYW5pbWF0aW9uOiBmYS1zcGluIDFzIGluZmluaXRlIHN0ZXBzKDgpO1xuICB9XG4gIEBrZXlmcmFtZXMgZmEtc3BpbiB7XG4gICAgMCUge1xuICAgICAgdHJhbnNmb3JtOiByb3RhdGUoMGRlZyk7XG4gICAgfVxuICAgIDEwMCUge1xuICAgICAgdHJhbnNmb3JtOiByb3RhdGUoMzYwZGVnKTtcbiAgICB9XG4gIH1cbiJdfQ== */</style>

<svg
  version="1.1"
  class="fa-icon {className}"
  class:fa-spin={spin}
  class:fa-pulse={pulse}
  class:fa-inverse={inverse}
  class:fa-flip-horizontal={flip === 'horizontal'}
  class:fa-flip-vertical={flip === 'vertical'}
  {x}
  {y}
  {width}
  {height}
  aria-label={label}
  role={label ? 'img' : 'presentation'}
  viewBox={box}
  {style}>
  <slot />
</svg>

<script>
  export let shadow = true;
  let classNames = '';
  export { classNames as class };

  // Overall controls for showing header and footer.
  export let showHeader = true;
  export let showFooter = true;

  // Mobile/desktop switches for header and footer. This and the showHeader/showFooter
  // must be true to show the element.
  export let showHeaderOnMobile = true;
  export let showFooterOnMobile = true;
  export let showHeaderOnDesktop = true;
  export let showFooterOnDesktop = true;
  // This pretty much only controls whether or not the shadow shows up on mobile.
  export let fullScreenOnMobile = false;

  // Card binds its div on this element
  export let element = undefined;

  let windowWidth = window.innerWidth;

  let baseClasses = ['rounded-md', 'border'];
  $: responsiveClasses = (fullScreenOnMobile
    ? baseClasses.map((c) => `sm:${c}`)
    : baseClasses
  ).join(' ');

  let headerClasses = '';
  let footerClasses = '';
  $: {
    if (showHeaderOnMobile && !showHeaderOnDesktop) {
      headerClasses = 'flex sm:hidden';
    } else if (showHeaderOnDesktop && !showHeaderOnMobile) {
      headerClasses = 'hidden sm:flex';
    } else {
      headerClasses = 'flex';
    }

    if (showFooterOnMobile && !showFooterOnDesktop) {
      footerClasses = 'flex sm:hidden';
    } else if (showFooterOnDesktop && !showFooterOnMobile) {
      footerClasses = 'hidden sm:flex';
    } else {
      footerClasses = 'flex';
    }
  }
</script>

<style lang="postcss">.header>:global(*){border-bottom-width:1px;border-color:#e5e7eb;background-color:rgb(67.77% 79.75% 85.73%)}.footer>:global(*),.header>:global(*){display:flex;flex-direction:row;flex-shrink:0;flex-grow:0;align-items:center;padding:.5rem;width:100%}.footer>:global(*){border-top-width:1px;border-color:#e5e7eb}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5vZGVfbW9kdWxlcy9AY2FyZXZveWFuY2UvdWkvc3JjL2NvbXBvbmVudHMvQ2FyZC5zdmVsdGUiLCI8bm8gc291cmNlPiJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFDRSxtQkNERix3QkFBQSxxQkFBQSwwQ0RLRSxDQUVBLHNDQ1BGLGFBQUEsbUJBQUEsY0FBQSxZQUFBLG1CQUFBLGNBQUEsVURXRSxDQUpBLG1CQ1BGLHFCQUFBLG9CRFdFIiwiZmlsZSI6Im5vZGVfbW9kdWxlcy9AY2FyZXZveWFuY2UvdWkvc3JjL2NvbXBvbmVudHMvQ2FyZC5zdmVsdGUiLCJzb3VyY2VzQ29udGVudCI6WyJcbiAgLmhlYWRlciA+IDpnbG9iYWwoKikge1xuICAgIEBhcHBseSBmbGV4IGZsZXgtcm93IGZsZXgtc2hyaW5rLTAgZmxleC1ncm93LTAgaXRlbXMtY2VudGVyXG4gICAgICAgIGJvcmRlci1iIGJvcmRlci1ncmF5LTIwMFxuICAgICAgICBwLTIgdy1mdWxsIGJnLXByaW1hcnktMzAwO1xuICB9XG5cbiAgLmZvb3RlciA+IDpnbG9iYWwoKikge1xuICAgIEBhcHBseSBmbGV4IGZsZXgtcm93IGZsZXgtc2hyaW5rLTAgZmxleC1ncm93LTAgaXRlbXMtY2VudGVyXG4gICAgICAgIGJvcmRlci10IGJvcmRlci1ncmF5LTIwMFxuICAgICAgICBwLTIgdy1mdWxsO1xuICB9XG4iLG51bGxdfQ== */</style>

<svelte:window bind:innerWidth={windowWidth} />

<div
  bind:this={element}
  class="flex flex-col flex-shrink-0 flex-grow-0 border-gray-400 bg-white {responsiveClasses}
  {classNames}"
  class:shadow-md={shadow && !fullScreenOnMobile}
  class:sm:shadow-md={shadow && fullScreenOnMobile}>

  {#if showHeader}
    <div class="header {headerClasses}">
      <slot name="header" />
    </div>
  {/if}

  <div class="flex flex-grow flex-col p-2 overflow-y-auto">
    <slot />
  </div>

  {#if showFooter}
    <div class="footer {footerClasses}">
      <slot name="footer" />
    </div>
  {/if}
</div>
